import {useEffect, useRef, useState} from "react";
import Manger_check_get from "../http/GET/Manger_check_get";
import {useOutletContext} from "react-router";


const MyData_page = () => {

    const cppc =useOutletContext().Change_password_page_change;
    const setModalOpen =useOutletContext().setModalOpen;
    const cnpc =useOutletContext().Change_name_page_change;


    const [loadin, setLoading] = useState(false);
    const [user, setUser] = useState({});

    useEffect(() => {

        async function fetchData() {
            const data = await Manger_check_get();

            console.log(data);

            if (data === "error") {
                console.log("error")
            } else if (data === 401) {
                setModalOpen(true);
            } else {
                setUser(data);
                setLoading(true);
            }

        }

        fetchData();
    }, []);


    return (
        <div className={"user_list_page"}>
            <div className={"user_data"}>
                {
                    loadin ?
                        <div className={"user_data_main"}>
                            <div className={"test"}>
                                <h1>information</h1>
                            </div>
                            <table>
                                <tbody>
                                <tr>
                                    <th>아이디</th>
                                    <td>{user.managerId}</td>
                                </tr>
                                <tr>
                                    <th>accountId</th>
                                    <td>{user.accountId}</td>
                                </tr>
                                <tr>
                                    <th>이름</th>
                                    <td><a onClick={() => {
                                        cnpc();
                                    }}>
                                        {user.name}
                                    </a></td>
                                </tr>
                                <tr>
                                    <th>비밀번호</th>
                                    <td>
                                        <div className={"user_data_password"}>
                                            <button onClick={() => {
                                                cppc();
                                            }}>change
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>권환 등급</th>
                                    <td>{user.role}</td>
                                </tr>

                                </tbody>
                            </table>
                        </div>
                        :
                        <div>

                        </div>
                }

            </div>
        </div>

    );
};

export default MyData_page;