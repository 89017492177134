import axios from "axios";

async function Role_check_get() {

    try {

        const response = await axios({
            url: `https://admin.clairaudience.co.kr/api/manager/v1/manager`,
            method: 'GET',
        });
        if (response.status === 200) {
            console.log("STATUS : ", response.data);
        } else {
            console.log("STATUS : ", response.status);
        }
        return response.data;
    } catch (error) {
        if(error.response.status === 401)
        {
            return 401;
        }
        else
        {
            console.log('확인 에러: ', error.response.status);
            return "error";
        }
    }


}

export default Role_check_get;