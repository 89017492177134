import { FaChevronRight, FaChevronLeft } from "react-icons/fa";



const UserList_pagenation = ({now_page, setNow_page, totalPages}) => {

    return (
        <div className={"pagenation_test"}>
            <nav className={"pagination"}>
                <ul>
                    <li className={"page"} onClick={()=>{
                        if(now_page <= 0) {
                            alert("Stop");
                        }
                        else{
                            let data = now_page -1;
                            setNow_page(data);
                        }
                    }}><FaChevronLeft id={"left_bt2"}/></li>

                    {Array.from({ length: totalPages }, (_, index) => (   // 이거 학습합시다.
                        <li className={"page"} onClick={() => {
                            setNow_page(index);
                        }} key={`UserList_pagenation${index+1}`}>{index+1}</li>
                    ))}

                    <li className={"page"} onClick={() => {

                        if(totalPages-1 <= now_page)
                        {
                            alert("Stop");
                        }
                        else
                        {
                            let data = now_page + 1;
                            setNow_page(data);
                        }

                    }}><FaChevronRight id={"right_bt2"}/></li>

                </ul>
            </nav>
        </div>
    );
};

export default UserList_pagenation;