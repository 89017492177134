import axios from "axios";

async function Logout_post() {

    try {
        const response = await axios.post('https://admin.clairaudience.co.kr/api/logout', {withCredentials: true});
        if (response.status === 200) {
            console.log("STATUS : ", response.data);
        } else {
            console.log("STATUS : ", response.status);
        }
        return true;
    } catch (error) {
        if (error.response.status === 401) {
            console.log("test1")
            return 401;
        } else {
            console.log('확인 에러: ', error.response.status);
            return "error";
        }
    }

}

export default Logout_post;

/*

????????????????????????????????????
    try {
        const response = await axios.post('http://test.admin.clairaudience.co.kr/api/logout', {withCredentials: true});
        if (response.status === 200) {
            console.log("STATUS : ", response.data);
        } else {
            console.log("STATUS : ", response.status);
        }
        return true;
    } catch (error) {
        if (error.response.status === 401) {
            console.log("test1")
            return 401;
        } else {
            console.log('확인 에러: ', error.response.status);
            return "error";
        }
    }



try {
        const response = await axios.post('http://test.admin.clairaudience.co.kr/api/logout', {withCredentials: true});
        if (response.status === 200) {
            console.log("STATUS : ", response.data);
        } else {
            console.log("STATUS : ", response.status);
        }
        return true;
    } catch (error) {
        if (error.response) {
            // error.response가 있는 경우 상태 코드를 확인
            if (error.response.status === 401) {
                console.log("test1")
                return 401;
            } else {
                console.log('확인 에러: ', error.response.status);
                return "error";
            }
        } else {
            // error.response가 정의되지 않은 경우 처리
            console.log('응답이 없습니다. 네트워크 문제 또는 서버에 접근할 수 없습니다.');
            return "network_error";
        }
    }









const handleLogin = () => {
    axios.post('http://test.admin.clairaudience.co.kr/api/logout', { withCredentials: true })
        .then(response => {
            //setLoggedIn(true);
            //setUser(username);
        })
        .catch(error => {
            console.error('Error logging in:', error);
        });
};
 */