import {useRef, useState} from "react";
import {
    Chart as ChartJS,
    ArcElement,
    BarElement,
    LineElement,
    PointElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend
} from "chart.js";
import {getElementsAtEvent, Line, Bar, Doughnut} from 'react-chartjs-2'
import Login_post from "../../http/POST/login_post";


ChartJS.register(
    ArcElement,
    LineElement,
    PointElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend,
    BarElement
);

const C_Dashboard_page = () => {

    console.log("hello");

    const data = {
        labels: ['Mon', 'Tue', 'Wed', 'box'],
        datasets: [{
            label: 'Weekdays',
            data: [30, 40, 66, 100],
            borderColor: 'aqua',
            backgroundColor: 'aqua',
            tension: 0.3,
            link: ['https://www.chartjs.ort', 'https://www.chartjs3.com', 'https://www.google.com']
        }]
    };

    const options = {
        maintainAspectRatio: false
    }

    const chartRef = useRef();
    const onClick = (event) => {
        if (getElementsAtEvent(chartRef.current, event).length > 0) {
            //console.log(getElementsAtEvent(chartRef.current, event));
            const clickDatasetIndex = getElementsAtEvent(chartRef.current, event)[0].datasetIndex;
            //console.log(clickDatasetIndex);
            const DataPoint = getElementsAtEvent(chartRef.current, event)[0].index;
            //console.log(DataPoint);
            const link = data.datasets[clickDatasetIndex].link[DataPoint];
            window.open(link, "_blank");
        }

    }

    const data2 = {
        labels: ['Mon', 'Tue', 'Wed', 'box'],
        datasets: [{
            label: 'Sales Numbers',
            data: [3, 6, 9, 3.69],
            backgroundColor: ['aqua', 'red'],
            borderWidth: 1
        }]
    };


    const options2 = {
        maintainAspectRatio: false
    }


    const data3 = {
        labels: ['yes', 'no'],
        datasets: [{
            label: 'Poll',
            data: [3, 6],
            backgroundColor: ['black', 'red'],
            borderColor: ['black', 'red']
        }]
    };

    const options3 = {
        maintainAspectRatio: false
    }

    return (
        <div className={"user_list_page"}>
            <div className={"dashboard_page"}>
                <div className={"dashboard_page_1"}>
                    <Line
                        data={data}
                        options={options}
                        onClick={onClick}
                        ref={chartRef}
                    ></Line>
                </div>
                <div className={"dashboard_page_2"}>
                    <Bar
                        data={data2}
                        options={options2}
                    >
                    </Bar>
                </div>
                <div className={"dashboard_page_3"}>
                    <Doughnut data={data3} options={options3}></Doughnut>
                </div>
            </div>
        </div>
    );
};

export default C_Dashboard_page;