import {useRef, useState} from "react";
import Login_Password_post from "../../http/PUT/Update_Password_post";
import Update_Expire_post from "../../http/PUT/Update_Expire_Date_post";
import Updata_version_put from "../../http/PUT/Updata_version_put";
import Delete_version from "../../http/DELETE/Delete_version";
import {useParams} from "react-router-dom";
import {useOutletContext} from "react-router";

const F_Delete_version_page = () => {

    const setModalOpen = useOutletContext().setModalOpen;
    const vpc = useOutletContext().Version_page_change;
    const [date, setDate] = useState();
    const {type} = useParams();

    return (
        <div className={"user_list_page"}>
            <div className={"change_password_page"}>
                <div className={"change_password_page_body"}>
                    <h1>버전 삭제</h1>
                    <table>
                        <tbody>
                        <tr>
                            <th>"삭제하기"입력</th>
                        </tr>
                        <tr>
                            <th>
                                <input type={"text"} value={date} onChange={(e) => {
                                    setDate(e.target.value)
                                }}/>
                            </th>
                        </tr>
                        </tbody>
                    </table>
                    <button onClick={async () => {


                        if (date === "삭제하기") {
                            const check = await Delete_version(type);
                            if (check === 410)
                            {
                                alert("삭제완료");
                                vpc();
                            }
                            else if(check === 401)
                            {
                                setModalOpen(true);
                            }
                            else {
                                console.log("error 발생");
                            }
                        }

                    }}>확인
                    </button>
                </div>
            </div>
        </div>
    );
};

export default F_Delete_version_page;