import {useRef, useState} from "react";
import Login_Password_post from "../../../http/PUT/Update_Password_post";
import Update_Expire_post from "../../../http/PUT/Update_Expire_Date_post";
import {useOutletContext} from "react-router";
import {useParams} from "react-router-dom";

const F_UpdateExpire_page = () => {

    const setModalOpen = useOutletContext().setModalOpen;
    const ulp = useOutletContext().UserList_page_change;

    const {id,date } = useParams()
    const [user_date, setUser_date] = useState(date);

    return (
        <div className={"user_list_page"}>
            <div className={"change_password_page"}>
                <div className={"change_password_page_body"}>
                    <h1>만료 날짜 갱신</h1>
                    <table>
                        <tbody>
                        <tr>
                            <th>날짜 입력</th>
                            <td>
                                <input type={"date"} value={user_date} onChange={(e) => {setUser_date(e.target.value)}} />
                            </td>
                        </tr>

                        </tbody>
                    </table>
                    <button onClick={async () => {
                        const json_data = {
                            "expireDate" : user_date
                        }
                        const check = await Update_Expire_post(id,json_data);
                        if(check === true)
                        {
                            alert("비번 변경완료");
                            ulp();
                        }
                        else if(check === false)
                        {
                            setModalOpen(true);
                        }
                        else {
                            console.log("error 발생");
                        }
                    }}>확인
                    </button>
                </div>
            </div>
        </div>
    );
};

export default F_UpdateExpire_page;