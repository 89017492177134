import {useEffect, useRef, useState} from "react";
import Delete_user from "../../http/DELETE/Delete_user";
import User_delete from "./User_delete/User_delete";
import UpdateExpire_page from "./UpdateExpire/UpdateExpire_page";
import Manger_check_get from "../../http/GET/Manger_check_get";
import {useOutletContext} from "react-router";
import {useParams} from "react-router-dom";
import User_data_get from "../../http/GET/User_data_get";

const F_UserData_page = () => {

    const cppc =useOutletContext().Change_password_page_change;
    const ulp =useOutletContext().UserList_page_change;
    const setModalOpen =useOutletContext().setModalOpen;
    const uepc =useOutletContext().Update_expire_page_change;
    const udpc =useOutletContext().Update_delivery_page_change;

    const { id } = useParams(); // useParams를 사용하여 URL의 파라미터를 가져옴


    const [Delete_model_open, setDelete_model_open] = useState(false);
    const [data, setData] = useState({
        "accountId":"",
        "userId":"",
        "userName":"",
        "userEmail":"",
        "delivery":"",
        "deviceSerialNumber":"",
        "date":"",
    });

    async function user_data_download () {
        const data = await User_data_get(id);

        if(data===false)
        {

        }
        else if(data==="error")
        {

        }
        else{
            setData(data);
        }
    }

    useEffect(() => {
        user_data_download();
    }, []);



    const User_delete_last_check = async () => {
        const delete_check = await Delete_user(id);

        if(delete_check===410)
        {
            console.log("삭제완료 하였습니다");
        }
        else if(delete_check === 401)
        {
            setDelete_model_open(false);
            setModalOpen(true);
            return;
        }
        else
        {
            alert("실패하였습니다.");
        }
        ulp();
    };


    return (
        <div className={"user_list_page"}>
            <div className={"user_list_page_body"}>
                <User_delete Delete ={Delete_model_open} setDelete = {setDelete_model_open} udlc={User_delete_last_check} />

                <div className={"user_data"}>
                    <div className={"user_data_main"}>
                        <div className={"test"}>
                            <h1>information</h1>
                        </div>
                        <table>
                            <tbody>
                            <tr>
                                <th>accountId</th>
                                <td>{data.accountId}</td>
                            </tr>
                            <tr>
                                <th>ID</th>
                                <td>{data.userId}</td>
                            </tr>
                            <tr>
                                <th>password</th>
                                <td>
                                    <div className={"user_data_password"}>
                                        <button onClick={async () => {
                                            cppc(data);
                                        }}>change
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>이름</th>
                                <td>{data.userName}</td>
                            </tr>
                            <tr>
                                <th>출산예정일</th>
                                <td>
                                    <a onClick={async () => {
                                        udpc(data);
                                    }}>
                                        {data.delivery}
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <th>기기 번호</th>
                                <td>{data.deviceSerialNumber}</td>
                            </tr>
                            <tr>
                                <th>가입날짜</th>
                                <td>{data.date}</td>
                            </tr>
                            <tr>
                                <th>만료 날짜</th>
                                <td>
                                    <a onClick={async ()=>{
                                        uepc(data);
                                    }}>
                                        {data.expireDate}
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <th>이메일</th>
                                <td>{data.userEmail}</td>
                            </tr>

                            </tbody>
                        </table>
                        <div className={"btn-delete"}>
                            <button className="noselect" onClick={() => {
                                setDelete_model_open(true);
                            }}><span className='text'>유저삭제</span><span className="icon"><svg
                                xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path
                                d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"/></svg></span>
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default F_UserData_page;