import {useEffect, useRef, useState} from "react";
import UserList_pagenation from "./UserList_pagenation";
import UserList_get from "../http/GET/UserList_get";
import {useOutletContext} from "react-router";


const A_UserList_page = () => {

    const upc = useOutletContext().UserData_page_change;

    const formatPhoneNumber = (phoneNumber) => {
        if (phoneNumber.length === 11) {
            return phoneNumber.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
        }
        return phoneNumber;
    };


    const [now_page, setNow_page] = useState(0);
    const [users, setUsers] = useState([]);
    const [totalPages, setTotalPages] = useState(1);


/*
    useEffect(() => {
        // 예제 데이터를 사용하여 상태 초기화
        const data = [
            {
                "managerId": 1,
                "account": "MasterAdmin",
                "name": "MasterAdmin",
                "role": "ADMIN"
            },
            {
                "managerId": 2,
                "account": "sangwon",
                "name": "이상원",
                "role": "ENGINEER"
            },
            {
                "managerId": 3,
                "account": "rmsgud",
                "name": "근형",
                "role": "ADMIN"
            },
            {
                "managerId": 4,
                "account": "KDH",
                "name": "name",
                "role": "ADMIN"
            },
            {
                "managerId": 5,
                "account": "rmsgud1",
                "name": "근형",
                "role": "ADMIN"
            }

            ]
        ;
        setUsers(data);
    }, []);
*/



    useEffect(() => {
        const fetchData = async () => {
            let data = await UserList_get(now_page);

            if (data) {
                setUsers(data.content); // data.content를 상태로 저장
                setTotalPages(data.totalPages); // data.totalPages를 상태로 저장
            } else {
                console.log("데이터가 없습니다.");
            }
        };

        fetchData();
    }, [now_page]);

    return (
        <div className={"user_list_page"}>
            <div className={"user_list_page_body"}>
                <div className={"검색창"}>
                    <input type="search" placeholder="Search Data..."/>
                </div>
                <div className={"list_page_base"}>
                    <table className={"list_table"}>
                        <thead className={"list_thead"}>
                        <tr className={"list_tr"}>
                            <th className={"list_th"}> account</th>
                            <th className={"list_th"}> managerId</th>
                            <th className={"list_th"}> name</th>
                            <th className={"list_th"}> role</th>
                            <th className={"list_th"}> 정보 변경</th>
                            <th className={"list_th"}> 활성화</th>
                        </tr>
                        </thead>
                        <tbody className={"list_tbody"}>

                        {users.map(user => (
                            <tr className={"list_tr"} key={user.accountId}>
                                <td className={"list_td"} onClick={() => {

                                }}>{user.accountId}</td>
                                <td className={"list_td"}>{user.managerId}</td>
                                <td className={"list_td"}>{user.name}</td>
                                <td className={"list_td"}>{user.role}</td>
                                <td className={"list_td"}>
                                    {
                                        user.enable ? <button onClick={() => {
                                            upc(user.accountId);
                                        }}>change</button> : <div></div>
                                    }
                                </td>
                                {
                                    user.enable ? <td style={{color: "green"}}><h3>O</h3></td> :
                                        <td style={{color: "red"}}><h3>X</h3></td>
                                }
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>

                <UserList_pagenation setNow_page={setNow_page} now_page={now_page} totalPages={totalPages}/>

            </div>
        </div>
    );
};

export default A_UserList_page;