import axios from "axios";

async function Delete_version(type) {

    try {
        const response = await axios({
            url: `https://admin.clairaudience.co.kr/api/engineer/v1/synesper-lite-cardio/version/${type}`,
            method: 'DELETE',
        });
        return 200;
    } catch (error) {
        if(error.response.status === 401)
        {
            return 401;
        }
        else if(error.response.status === 410)
        {
            console.log('확인 에러: ', error.response.status);
            return 410;
        }
    }

}

export default Delete_version;