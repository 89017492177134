import {useRef, useState} from "react";

const Modal_page = ({modalOpen,setModalOpen}) => {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const modalBackground = useRef();

    return (
        <div>
            {
                modalOpen &&
                <div className={'admin-modal-session-container'} ref={modalBackground} onClick={e => {
                    if (e.target === modalBackground.current) {
                        setModalOpen(true);
                    }
                }}>
                    <div className={'admin-modal-session-content'}>
                        <p>리액트로 모달 구현하기</p>
                        <button className={'modal-close-btn'} onClick={() => {

                            window.location.href = "https://admin.clairaudience.co.kr/error-401";

                        }}>
                            모달 닫기
                        </button>
                    </div>
                </div>
            }
        </div>
    );
};

export default Modal_page;