import {useRef, useState} from "react";
import {Dropdown} from "react-bootstrap";
import Update_Expire_post from "../../http/PUT/Update_Expire_Date_post";
import {useOutletContext} from "react-router";
import {useParams} from "react-router-dom";

const A_UpdateExpire_page = () => {

    const setModalOpen = useOutletContext().setModalOpen;
    const upc = useOutletContext().UserData_page_change;

    const {id,role } = useParams()
    const [date, setDate] = useState(role);

    return (
        <div className={"user_list_page"}>
            <div className={"change_password_page"}>
                <div className={"change_password_page_body"}>
                    <h1>역할 변경</h1>
                    <Dropdown className={"role-dropdown"}>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                            {date}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={()=>{setDate("ADMIN")}}>ADMIN</Dropdown.Item>
                            <Dropdown.Item onClick={()=>{setDate("ENGINEER")}}>ENGINEER</Dropdown.Item>
                            <Dropdown.Item onClick={()=>{setDate("MANAGER")}}>MANAGER</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <button onClick={async () => {

                        const json_data = {
                            "role" : date
                        }
                        const check = await Update_Expire_post(id,json_data);
                        if(check === true)
                        {
                            upc(id);
                        }
                        else if(check === false)
                        {
                            setModalOpen(true);
                        }
                        else {
                            console.log("error 발생");
                        }
                    }}>확인
                    </button>
                </div>
            </div>
        </div>
    );
};

export default A_UpdateExpire_page;