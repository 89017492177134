import {useEffect, useRef, useState} from "react";
import Login_Password_post from "../../../http/PUT/Update_Password_post";
import Update_delivery_put from "../../../http/PUT/Update_delivery_put";
import {useOutletContext} from "react-router";
import User_data_get from "../../../http/GET/User_data_get";
import {useParams} from "react-router-dom";



const F_Update_delivery_page = () => {


    const setModalOpen = useOutletContext().setModalOpen;
    const ulp = useOutletContext().UserList_page_change;
    const data = useOutletContext().u_data;

    const {id,name,date } = useParams()

    const [user_name, setName] = useState(name);
    const [delivery, setDelivery] = useState(date);






    return (
        <div className={"user_list_page"}>
            <div className={"change_password_page"}>
                <div className={"change_password_page_body"}>
                    <h1>이름 및 출산예정일 변경</h1>
                    <table>
                        <tbody>
                        <tr>
                            <th>유저 이름</th>
                            <td>
                                <input type={"text"} value={user_name} onChange={(e) => setName(e.target.value)}/>
                            </td>
                        </tr>
                        <tr>
                            <th>유저 출산예정일</th>
                            <td>
                                <input type={"date"} value={delivery} onChange={(e) => {
                                    setDelivery(e.target.value)
                                }}/>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <button onClick={async () => {
                        const json_data = {
                            "userName" : user_name,
                            "delivery" : delivery
                        }

                        let chack = await Update_delivery_put(id,json_data);

                        if (chack === true) {
                            alert("이름 출산예정일 변경완료");
                            ulp();
                        } else if (chack === false) {
                            setModalOpen(true);
                        } else {
                            console.log("error 발생");
                        }
                    }}>확인
                    </button>
                </div>
            </div>
        </div>
    );
};

export default F_Update_delivery_page;