import axios from "axios";

async function Test_pcg_get(measureId, setPcg_d,setError) {

    fetch(`https://admin.clairaudience.co.kr/api/engineer/v1/synesper-lite-family/measure/pcg/${measureId}`)
        .then(response => {

            if (!response.ok) {
                setError("error: 데이터 없음");
            }
            else{
                setPcg_d(response.body);
            }

        })
        .catch(error => {

            console.error('There was a problem with the fetch operation:', error);
        });


}

export default Test_pcg_get;