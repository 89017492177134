import {useRef, useState} from "react";
import Login_Password_post from "../../http/PUT/Update_Password_post";
import Update_Expire_post from "../../http/PUT/Update_Expire_Date_post";
import Updata_version_put from "../../http/PUT/Updata_version_put";
import {useParams} from "react-router-dom";
import {useOutletContext} from "react-router";

const F_Updata_version_page = () => {


    const setModalOpen = useOutletContext().setModalOpen;
    const vpc = useOutletContext().Version_page_change;
    const [date, setDate] = useState();
    const {type} = useParams();



    return (
        <div className={"user_list_page"}>
            <div className={"change_password_page"}>
                <div className={"change_password_page_body"}>
                    <h1>버전 업데이트</h1>
                    <table>
                        <tbody>
                        <tr>
                            <th>버전 입력</th>
                            <th>
                                <input type={"text"} value={date} onChange={(e) => {setDate(e.target.value)}} />
                            </th>
                        </tr>
                        </tbody>
                    </table>
                    <button onClick={async () => {

                        const json_data = {
                            "version" : date
                        }
                        const check  = await Updata_version_put(type,json_data);
                        if(check === true)
                        {
                            alert("버전 변경완료");
                            vpc();
                        }
                        else if(check === false)
                        {
                            setModalOpen(true);
                        }
                        else {
                            console.log("error 발생");
                        }
                    }}>변경
                    </button>
                </div>
            </div>
        </div>
    );
};

export default F_Updata_version_page;