import {useEffect, useRef, useState} from "react";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";
import {Link, useParams} from "react-router-dom";
import UserList_pagenation from "./UserList_pagenation";
import UserList_get from "../../http/GET/UserList_get";
import Role_check_get from "../../http/GET/Role_check_get";
import {useOutletContext} from "react-router";


const C_UserList_page = () => {

    const setModalOpen = useOutletContext().setModalOpen;
    const upc = useOutletContext().UserData_page_change;
    const mpc = useOutletContext().Measure_page_change;


    const formatPhoneNumber = (phoneNumber) => {
        if (phoneNumber.length === 11) {
            return phoneNumber.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
        }
        return phoneNumber;
    };


    const [now_page, setNow_page] = useState(0);
    const [users, setUsers] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [role, setrole] = useState(false);
    const [search_name,setSearch_name] = useState("");

    useEffect(() => {
        const fetchData = async () => {

            const role_data = await Role_check_get();
            if(role_data === 401)
            {
                setModalOpen(true);
            }else if(role_data === "error")
            {
                console.log("error");
            }else{

                if(role_data.role === "MANAGER")
                {
                    setrole(false);
                }else{
                    setrole(true);
                }

                let data = await UserList_get(now_page);
                if (data) {
                    setUsers(data.content); // data.content를 상태로 저장
                    setTotalPages(data.totalPages); // data.totalPages를 상태로 저장
                } else {
                    console.log("데이터가 없습니다.");
                }
            }
        };
        fetchData();
    }, [now_page]);




    return (
        <div className={"user_list_page"}>

            <div className={"user_list_page_body"}>
                <div className={"검색창"}>
                <input type="search" placeholder="Search Data..."/>
                </div>
                <div className={"list_page_base"}>
                    <table className={"list_table"}>
                        <thead className={"list_thead"}>
                        <tr className={"list_tr"}>
                            <th className={"list_th"}> ID</th>
                            <th className={"list_th"}> 이름</th>
                            <th className={"list_th"}> 성별</th>
                            <th className={"list_th"}> 생년월일</th>
                            <th className={"list_th"}> 기기 번호</th>
                            <th className={"list_th"}> 전화번호</th>
                            <th className={"list_th"}> 생성날짜</th>
                            <th className={"list_th"}> 심박</th>
                        </tr>
                        </thead>
                        <tbody className={"list_tbody"}>

                        {users.map(user => (
                            <tr className={"list_tr"} key={user.accountId}>
                                <td className={"list_td"} onClick={async () => {
                                    upc(user);

                                }}>{user.userId}</td>
                                <td className={"list_td"}>{user.userName}</td>
                                <td className={"list_td"}>{user.gender === 'MALE' ? '남성' : '여성'}</td>
                                <td className={"list_td"}>{user.birth}</td>
                                <td className={"list_td"}>{user.deviceSerialNumber}</td>
                                <td className={"list_td"}>{formatPhoneNumber(user.phoneNumber)}</td>
                                <td className={"list_td"}>{user.createDate.split('T')[0]}</td>
                                {
                                    role ?
                                        <td className={"list_td"}>
                                            <button onClick={async () => {
                                                mpc(user);

                                            }}>download
                                            </button>
                                        </td>
                                        : null
                                }
                                {/* 심박 정보가 없으므로 N/A로 표시 */}
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>

                <UserList_pagenation setNow_page = {setNow_page} now_page = {now_page} totalPages = {totalPages}/>

            </div>
        </div>
    );
};

export default C_UserList_page;