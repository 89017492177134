import React from "react";
import main_img from "../image_test/main_img.png";
import Login_post from "../test/http_login";
import Logout_post from "../test/http_logout";
import Manger_check_get from "../test/Manger_check_get";


function Initial_page({onMove_page, login,setLogin,check_off,setModalOpen,role}) {



    return <div className={"inner"}>
        <div className={"initial-page"}>
            <div id={"initial-img"}>
                <img src={main_img}/>
            </div>
            <div id={"initial-button"}>
                {
                    login ?
                        <div>
                            <button className={"initial-page-btn"} onClick={async () => {
                                const check = await Manger_check_get();

                                if (check === true) {
                                    window.location.href = "https://admin.clairaudience.co.kr/synesper-lite-family";
                                } else if (check === false) {
                                    setModalOpen(true);
                                } else {
                                    alert("error 발생");
                                }

                            }}>
                                <b>family</b>
                            </button>
                            <button className={"initial-page-btn"} onClick={async () => {

                                const check = await Manger_check_get();

                                if (check === true) {
                                    window.location.href = "https://admin.clairaudience.co.kr/synesper-lite-cardio";
                                } else if (check === false) {
                                    setModalOpen(true);
                                } else {
                                    alert("error 발생");
                                }

                            }}>
                                <b>cardio</b>
                            </button>
                            {
                                role ? <button className={"initial-page-btn"} onClick={async () => {

                                    const check = await Manger_check_get();

                                    if (check === true) {
                                        window.location.href = "https://admin.clairaudience.co.kr/admin";
                                    } else if (check === false) {
                                        setModalOpen(true);
                                    } else {
                                        alert("error 발생");
                                    }

                                }}>
                                    <b>admin page</b>
                                </button> : null
                            }
                            <button className={"initial-page-btn"} onClick={async () => {

                                const check = await Manger_check_get();

                                if (check === true) {
                                    window.location.href = "https://admin.clairaudience.co.kr/manager";
                                } else if (check === false) {
                                    setModalOpen(true);
                                } else {
                                    alert("error 발생");
                                }

                            }}>
                                <b>My page</b>
                            </button>


                            <button className={"initial-page-btn"} onClick={
                                async () => {
                                    await Logout_post();
                                    onMove_page();
                                    setLogin(false);
                                    check_off();
                                }
                            }>
                                <b>LOG OUT</b>
                            </button>
                        </div>
                        :
                        <button className={"initial-page-btn"} onClick={onMove_page}>
                            <b>LOG IN</b>
                        </button>
                }
            </div>
        </div>
    </div>
}

export default Initial_page;