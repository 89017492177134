import axios from "axios";

async function Download_ppg_get(measureId) {

    try {

        const response = await axios({
            url: `https://admin.clairaudience.co.kr/api/engineer/v1/synesper-lite-family/measure/ppg/${measureId}`,
            method: 'GET',
        });
        if (response.status === 200) {
            console.log("성공");
            console.log("STATUS : ", response.data);
            return response.data;
        } else {
            console.log("STATUS : ", response.status);
        }

        if(response.status===500)
        {
            console.log("error error error error error error error error ");
            return 500;
        }
    } catch (error) {
        console.log("error check");
        console.log(error.status)
        if(error.response.status === 401)
        {
            return 0;
        }
        else
        {
            return 1;
        }
    }


}

export default Download_ppg_get;