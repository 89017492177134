import {useRef, useState} from "react";
import {
    Chart as ChartJS,
    ArcElement,
    BarElement,
    LineElement,
    PointElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend
} from "chart.js";
import {getElementsAtEvent, Line, Bar, Doughnut} from 'react-chartjs-2'
import Login_post from "../../http/POST/login_post";


ChartJS.register(
    ArcElement,
    LineElement,
    PointElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend,
    BarElement
);

const Dashboard_page = () => {

    let box = {
        "data": [367, 367, 381, 422, 455, 455, 455, 455, 455, 459, 459, 459, 459, 459, 459, 459, 459, 459, 459, 461, 461, 461, 461, 461, 461, 461, 461, 461, 461, 461, 461],
        "labels": ["2024-07-14", "2024-07-15", "2024-07-16", "2024-07-17", "2024-07-18", "2024-07-19", "2024-07-20", "2024-07-21", "2024-07-22", "2024-07-23", "2024-07-24", "2024-07-25", "2024-07-26", "2024-07-27", "2024-07-28", "2024-07-29", "2024-07-30", "2024-07-31", "2024-08-01", "2024-08-02", "2024-08-03", "2024-08-04", "2024-08-05", "2024-08-06", "2024-08-07", "2024-08-08", "2024-08-09", "2024-08-10", "2024-08-11", "2024-08-12", "2024-08-13"]
    }

    const data = {
        labels: box.labels,
        datasets: [{
            label: '누적 일별 데이터 개수',
            data: box.data,
            borderColor: 'aqua',
            backgroundColor: 'aqua',
            tension: 0.1,
        }]
    };

    const options = {
        maintainAspectRatio: false
    }

    const chartRef = useRef();
    const onClick = (event) => {
        if (getElementsAtEvent(chartRef.current, event).length > 0) {
            //console.log(getElementsAtEvent(chartRef.current, event));
            const clickDatasetIndex = getElementsAtEvent(chartRef.current, event)[0].datasetIndex;
            //console.log(clickDatasetIndex);
            const DataPoint = getElementsAtEvent(chartRef.current, event)[0].index;
            //console.log(DataPoint);
            const link = data.datasets[clickDatasetIndex].link[DataPoint];
            window.open(link, "_blank");
        }

    }

    let box2 = {
        "data": [62, 62, 63, 63, 63, 63, 63, 63, 63, 64, 64, 64, 64, 64, 64, 64, 64, 64, 64, 64, 64, 64, 64, 64, 64, 64, 64, 64, 64, 64, 64],
        "labels": ["2024-07-14", "2024-07-15", "2024-07-16", "2024-07-17", "2024-07-18", "2024-07-19", "2024-07-20", "2024-07-21", "2024-07-22", "2024-07-23", "2024-07-24", "2024-07-25", "2024-07-26", "2024-07-27", "2024-07-28", "2024-07-29", "2024-07-30", "2024-07-31", "2024-08-01", "2024-08-02", "2024-08-03", "2024-08-04", "2024-08-05", "2024-08-06", "2024-08-07", "2024-08-08", "2024-08-09", "2024-08-10", "2024-08-11", "2024-08-12", "2024-08-13"]
    }

    const data2 = {
        labels: box2.labels,
        datasets: [{
            label: '누적 일별 유저수',
            data: box2.data,
            backgroundColor: ['aqua', 'red'],
            borderWidth: 1
        }]
    };

    const options2 = {
        maintainAspectRatio: false
    }

    const options3 = {
        maintainAspectRatio: false
    }


    let box3 = {
        "data": [0,0,2,0,0,0,0,0,0,0,0,0,0,0,0,2,0,0,0,0,0,0,0,1,0,0,0,1,0,0,0],
        "labels": ["2024-07-31","2024-08-01","2024-08-02","2024-08-03","2024-08-04","2024-08-05","2024-08-06","2024-08-07","2024-08-08","2024-08-09","2024-08-10","2024-08-11","2024-08-12","2024-08-13","2024-08-14","2024-08-15","2024-08-16","2024-08-17","2024-08-18","2024-08-19","2024-08-20","2024-08-21","2024-08-22","2024-08-23","2024-08-24","2024-08-25","2024-08-26","2024-08-27","2024-08-28","2024-08-29","2024-08-30"]
    }

    const data3 = {
        labels: box3.labels,
        datasets: [{
            label: '일별 데이터 등록 개수',
            data: box3.data,
            backgroundColor: ['red'],
            borderWidth: 1
        }]
    };


    const currentDate = new Date();
    const startOfWeek = new Date(currentDate);


    const style_a = {
        padding:"4px",
        borderRadius: "10px",
        width:"60px",
        backgroundColor: "rgba(0,0,0,0.1)",
        color:"black"
    }




    return (
        <div className={"user_list_page"}>
            <div className={"dashboard_page"}>
                <div className={"DashBoard"}>
                    <div className={"DashBoard-content"}>
                        <div className={"DashBoard-content-head"}
                             style={{color: "#B3B3B3", fontSize: "18px", fontWeight: "bolder"}}>Dashboard
                        </div>
                        <div className={"DashBoard-content-body"}>
                            <div className={"DashBoard-item p-lg-2"}>
                                <div style={{fontSize: "18px", padding: "5px", fontWeight: "bolder", height: "13%"}}>
                                    Daily Collective Data
                                </div>
                                <div className={"dashboard_page_1"} style={{height: "87%"}}>
                                    <Line
                                        data={data}
                                        options={options}
                                        onClick={onClick}
                                        ref={chartRef}
                                    ></Line>
                                </div>
                            </div>
                            <div className={"DashBoard-item p-lg-2"}>
                                <div style={{fontSize: "18px", padding: "5px", fontWeight: "bolder", height: "13%"}}>
                                    Accumulated User Data
                                </div>
                                <div className={"dashboard_page_2"} style={{height: "87%"}}>
                                    <Bar
                                        data={data2}
                                        options={options2}
                                    >
                                    </Bar>
                                </div>
                            </div>
                            <div className={"DashBoard-item DashBoard-data"}>
                                <div style={{padding: "20px 20px 30px 30px", fontWeight: "bolder", fontSize: "20px"}}>
                                    Dataspaces
                                </div>

                                <div className={"DashBoard-item-object"}>
                                    <div className={"Dataspaces"} style={{width: "100%"}}>
                                        <table style={{width: "100%"}}>
                                            <thead>
                                            <tr>
                                                <th>Accumulated Data</th>
                                                <td>
                                                    Amount
                                                </td>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <th>누적 데이터</th>
                                                <td>
                                                    <div style={style_a}>
                                                        87
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>누적 유저</th>
                                                <td>
                                                    <div style={style_a}>
                                                        87
                                                    </div>
                                                </td>
                                            </tr>

                                            </tbody>
                                        </table>
                                    </div>


                                    <div className={"Dataspaces"} style={{width: "100%"}}>
                                        <table style={{width: "100%"}}>
                                            <thead>
                                            <tr>
                                                <th>Periodic Data</th>
                                                <td>
                                                    Amount
                                                </td>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <th>이번 달 데이터</th>
                                                <td>
                                                    <div style={style_a}>
                                                        87
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>이번 주 데이터</th>
                                                <td>
                                                    <div style={style_a}>
                                                        87
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>어제 데이터</th>
                                                <td>
                                                    <div style={style_a}>
                                                        {currentDate.getDate()}
                                                    </div>
                                                </td>
                                            </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard_page;