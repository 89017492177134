import './Error-401.css';
import {useEffect, useState} from "react";
import Login_Page from "./login/login_page";
import './login/login page.css';

function Error_page() {



    return (
        <div className={"main_page"}>
            <div>
                <Login_Page/>
            </div>
        </div>
    );
}

export default Error_page;
