import {useRef, useState} from "react";
import Create_version_post from "../../http/POST/Create_version_post";
import {useOutletContext} from "react-router";

const C_Create_version_page = () => {

    const setModalOpen = useOutletContext().setModalOpen;
    const vpc = useOutletContext().Version_page_change;

    const [type, setType] = useState();
    const [version, setVersion] = useState();



    return (
        <div className={"user_list_page"}>
            <div className={"create_version_page"}>
                <div className={"create_version_page_body"}>
                    <h1>버전 생성</h1>
                    <table>
                        <tbody>
                        <tr>
                            <th>type</th>
                            <td>
                                <input type={"text"} value={type} onChange={(e) => {
                                    setType(e.target.value)
                                }}/>
                            </td>
                        </tr>
                        <tr>
                            <th>version</th>
                            <td>
                                <input type={"text"} value={version} onChange={(e) => {
                                    setVersion(e.target.value)
                                }}/>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <button onClick={async () => {

                        const json_data = {
                            "type": type,
                            "version": version
                        }
                        const check = await Create_version_post(json_data);
                        if(check === true)
                        {
                            alert("생성완료");
                            vpc();
                        }
                        else if(check === false)
                        {
                            setModalOpen(true);
                        }
                        else {
                            console.log("error 발생");
                        }
                    }}>확인
                    </button>
                </div>
            </div>
        </div>
    );
};

export default C_Create_version_page;