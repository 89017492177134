import {useRef, useState} from "react";
import Updata_Password_post from "../../http/PUT/Update_Password_post";
import Updata_Name_post from "../../http/PUT/Update_Name_post";
import {useOutletContext} from "react-router";


const M_Change_name_page = () => {


    const setModalOpen =useOutletContext().setModalOpen;
    const mpc =useOutletContext().Mydata_page_change;
    const [name, setName] = useState(false);

    return (
        <div className={"user_list_page"}>
            <div className={"change_name_page"}>
                <div className={"change_name_page_body"}>

                    <h1>이름 변경</h1>
                    <table>
                        <tbody>
                        <tr>
                            <th>변경할 이름</th>
                            <td>
                                <input type={"text"} onChange={(e) => setName(e.target.value)}/>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <button onClick={async () => {

                        console.log("test");
                        let chack = await Updata_Name_post(name);
                        console.log(chack);
                        if (chack === true) {
                            alert("이름 변경완료");
                            mpc();
                        } else if (chack === false) {
                            setModalOpen(true);
                        } else {
                            console.log("error 발생");
                        }



                    }}>확인
                    </button>
                </div>
            </div>
        </div>
    );
};

export default M_Change_name_page;