import {useRef, useState} from "react";
import Login_Password_post from "../../http/PUT/Update_Password_post";
import {useOutletContext} from "react-router";
import {useParams} from "react-router-dom";

const A_Change_password_page = () => {



    const setModalOpen = useOutletContext().setModalOpen;
    const upc = useOutletContext().UserData_page_change;

    const {id} = useParams()

    const [password, setPassword] = useState(false);
    const [confirmPassword,setConfirmPassword] = useState(false);

    return (
        <div className={"user_list_page"}>
            <div className={"change_password_page"}>
                <div className={"change_password_page_body"}>
                    <h1>비밀번호변경</h1>
                    <table>
                        <tbody>
                        <tr>
                            <th>새 비밀번호</th>
                            <td>
                                <input type={"password"} onChange={(e) => setPassword(e.target.value)}/>
                            </td>
                        </tr>
                        <tr>
                            <th>새 비밀번호 확인</th>
                            <td>
                                <input type={"password"} onChange={(e) => setConfirmPassword(e.target.value)}/>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <button onClick={async () => {

                        if(password === confirmPassword)
                        {
                            let chack = await Login_Password_post(id,password,confirmPassword);

                            if(chack === true)
                            {
                                alert("비번 변경완료");
                                upc(id);
                            }
                            else if(chack === false)
                            {
                                setModalOpen(true);
                            }
                            else {
                                console.log("error 발생");
                            }
                        }
                        else {
                            alert("비번 다름 ㅅㄱ");
                        }

                    }}>확인
                    </button>
                </div>
            </div>
        </div>
    );
};

export default A_Change_password_page;