import {useEffect, useRef, useState} from "react";
import Delete_user from "../../http/DELETE/Delete_user";
import User_delete from "./User_delete/User_delete";
import {useOutletContext} from "react-router";
import {useParams} from "react-router-dom";
import C_User_data_get from "../../http/GET/User_data_get";

const C_UserData_page = () => {


    const cppc =useOutletContext().Change_password_page_change;
    const ulp =useOutletContext().UserList_page_change;
    const setModalOpen =useOutletContext().setModalOpen;
    const uepc =useOutletContext().Update_expire_page_change;

    const { id } = useParams(); // useParams를 사용하여 URL의 파라미터를 가져옴




    const [Delete_model_open, setDelete_model_open] = useState(false);
    const [data, setData] = useState({
        "accountId":"",
        "userId":"",
        "userName":"",
        "gender":"",
        "birth":"",
        "deviceSerialNumber":"",
        "phoneNumber":"",
        "createDate":"",
        "expireDate":"",
        "userEmail":""
    });

    const User_delete_last_check = async () => {
        const delete_check = await Delete_user(id);

        if(delete_check===410)
        {
            console.log("삭제완료 하였습니다");
        }
        else if(delete_check === 401)
        {
            setDelete_model_open(false);
            setModalOpen(true);
            return;
        }
        else
        {
            alert("실패하였습니다.");
        }
        ulp();
    };

    async function user_data_download () {
        const data = await C_User_data_get(id);

        if(data===false)
        {

        }
        else if(data==="error")
        {

        }
        else{
            setData(data);
        }
    }

    useEffect(() => {
        user_data_download();
    }, []);


    return (
        <div className={"user_list_page"}>
            <div className={"user_list_page_head"}></div>
            <div className={"user_list_page_body"}>
                <User_delete Delete ={Delete_model_open} setDelete = {setDelete_model_open} udlc={User_delete_last_check} />
                <div className={"cardio-user_data"}>
                    <div className={"cardio-user_data_main"}>
                        <div className={"cardio-user_data_title"}>
                            <h1>information</h1>
                        </div>
                        <div className={"table_body"}>
                            <table>
                                <tbody>
                                <tr>
                                    <th>accountId</th>
                                    <td>{data.accountId}</td>
                                </tr>
                                <tr>
                                    <th>ID</th>
                                    <td>{data.userId}</td>
                                </tr>
                                <tr>
                                    <th>password</th>
                                    <td>
                                        <div className={"user_data_password"}>
                                            <button onClick={async () => {
                                                cppc(data);
                                            }}>change
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>이름</th>
                                    <td>{data.userName}</td>
                                </tr>
                                <tr>
                                    <th>성별</th>
                                    <td>{data.gender === 'MALE' ? '남성' : '여성'}</td>
                                </tr>
                                <tr>
                                    <th>생년월일</th>
                                    <td>{data.birth}</td>
                                </tr>
                                </tbody>
                            </table>
                            <table>
                                <tbody>
                                <tr>
                                    <th>기기 번호</th>
                                    <td>{data.deviceSerialNumber}</td>
                                </tr>
                                <tr>
                                    <th>전화번호</th>
                                    <td>{data.phoneNumber}</td>
                                </tr>
                                <tr>
                                    <th>가입날짜</th>
                                    <td>{data.createDate}</td>
                                </tr>
                                <tr>
                                    <th>만료 날짜</th>
                                    <td>
                                        <a onClick={async () => {
                                            uepc(data);
                                        }}>
                                            {data.expireDate}
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <th>이메일</th>
                                    <td>{data.userEmail}</td>
                                </tr>
                                </tbody>
                            </table>

                        </div>
                        <div className={"btn-delete"}>
                            <button className="noselect" onClick={() => {
                                setDelete_model_open(true);
                            }}><span className='text'>유저삭제</span><span className="icon"><svg
                                xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path
                                d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"/></svg></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default C_UserData_page;